// NPM
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import classNames from 'classnames'

// CONTEXT
import { GlobalContext } from '../context/globalContext'

// HOOKS
import { useOptionsData } from '../hooks/optionsData'

// COMPONENTS
import PreHeader from './PreHeader'
import MenuToggler from './MenuToggler'
import MenuModal from './MenuModal'

// TYPES
import type { GlobalContextProps } from '../types/custom-types'

// ASSETS
import SvgLogoBlack from '../images/lava-project-logo-black.inline.svg'

const Header: React.FC = () => {
  const location = useLocation()
  const { siteOptions } = useOptionsData()
  const { isMenuOpen, isScrolled, isScrollingDown }: GlobalContextProps = useContext(GlobalContext)
  const isHomePage = location.pathname === '/'
  const preHeaderActive = siteOptions?.lavaSiteOptions?.siteOptionsPreHeader?.active
  const preHeaderColor = siteOptions?.lavaSiteOptions?.siteOptionsPreHeader?.color
  const preHeaderText = siteOptions?.lavaSiteOptions?.siteOptionsPreHeader?.text
  const preHeaderLink = siteOptions?.lavaSiteOptions?.siteOptionsPreHeader?.link

  return (
    <>
      {preHeaderActive &&
        <PreHeader
          color={preHeaderColor}
          text={preHeaderText}
          link={preHeaderLink}
        />
      }

      <header className={
        classNames(
          'site-header',
          'fixed',
          'flex',
          'justify-between',
          'items-start',
          'right-0',
          'left-0',
          'z-50',
          'transition',
          'transition-all',
          'duration-500',
          'ease-in-out',
          'ps-4',
          'pe-4',
          'md:ps-8',
          'md:pe-8',
          'pb-3',
          'md:pb-4',
          { 'pt-4': !preHeaderActive },
          { 'pt-10 md: pt-8': preHeaderActive },
          { [isScrolled && isScrollingDown && !isMenuOpen ? '-top-36' : 'top-0']: true },
          { 'bg-primary border-b border-black border-solid': (isScrolled && !isScrollingDown) || isMenuOpen }
        )
      }>
        <div className={classNames(
          'logo',
          'ml-1'
        )}>
          <Link
            to="/"
            className="block uppercase text-2xl font-circular-book my-auto"
          >
            <span className={classNames(
              'relative block',
              'w-48',
              { 'pt-6': preHeaderActive }
            )}>
              <SvgLogoBlack
                className={classNames(
                  'w-auto',
                  'h-10',
                  'md:w-full',
                  'md:h-auto',
                  'mb-2',
                  { invisible: isHomePage && !isMenuOpen && !isScrolled }
                )}
              />
              <span className={classNames(
                'block',
                'uppercase',
                'text-sm',
                'mb-0',
                { invisible: isHomePage && !isMenuOpen && !isScrolled }
              )}>
                By Il Magma
              </span>

              {(isHomePage && !isMenuOpen && !isScrolled) &&
                <span className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl text-black font-circular-book">
                  By Il Magma
                </span>
              }
            </span>
          </Link>

        </div>

        <MenuToggler />
      </header>

      <MenuModal />
    </>
  )
}

export default Header
