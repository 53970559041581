// NPM
import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import classNames from 'classnames'

// CONTEXT
import { GlobalContext } from '../context/globalContext'

// HOOKS
import { useNavigationData } from '../hooks/navigationData'

// TYPES
import type { IGatsbyImageData } from 'gatsby-plugin-image'
interface rolloverTimelinesType {
  id: string
  timeline: gsap.core.Timeline
}

interface rolloverImagesType {
  id: string
  imageData: IGatsbyImageData
}

const MenuModal = (): JSX.Element => {
  const { mainNavigation, secondaryNavigation } = useNavigationData()

  const itemsCount = mainNavigation?.menuItems?.nodes.length ?? 0

  const { isMenuOpen, closeMenu } = useContext(GlobalContext)

  const modalRef = useRef<HTMLDivElement>(null)
  const rolloferWrapperRef = useRef<HTMLDivElement>(null)
  const modalTimelineRef = useRef<gsap.core.Timeline | null>(null)
  const rolloverTimelinesRef = useRef<rolloverTimelinesType[]>([])
  const rolloverImagesRef = useRef<HTMLDivElement[]>([])

  const rolloverImages: rolloverImagesType[] = mainNavigation?.menuItems?.nodes.map((elem) => {
    if (elem.connectedNode?.node) {
      if ('featuredImage' in elem.connectedNode?.node) {
        if (elem.connectedNode?.node?.featuredImage) {
          if ('localFile' in elem.connectedNode?.node?.featuredImage.node) {
            if (elem.connectedNode?.node?.featuredImage.node.localFile?.childImageSharp?.gatsbyImageData) {
              const imageData = getImage(elem.connectedNode?.node?.featuredImage.node.localFile.childImageSharp.gatsbyImageData)
              if (imageData) {
                return {
                  id: elem.id,
                  imageData
                }
              }
            }
          }
        }
      }
    }
    return null
  }).filter((elem) => elem !== null) as rolloverImagesType[]

  rolloverImagesRef.current = rolloverImages.map((_, i) => rolloverImagesRef.current[i] ?? React.createRef<HTMLDivElement>())

  useGSAP(() => {
    if (modalTimelineRef.current !== null) {
      isMenuOpen ? modalTimelineRef.current.play() : modalTimelineRef.current.reverse()
    }
  }, { dependencies: [isMenuOpen] })

  useEffect(() => {
    if (rolloverImages.length > 0 && rolloverImagesRef.current.length > 0) {
      rolloverImages.filter((elem, index) => rolloverImagesRef.current[index] !== null).forEach((elem, index) => {
        // const node = rolloverImagesRef.current[index]
        if (rolloverImagesRef.current[index] !== null) {
          const timeline = gsap.timeline({ paused: true, reversed: true })
          timeline.to(rolloverImagesRef.current[index], { autoAlpha: 1, right: '-250px', duration: 0.5, ease: 'power2.out' })
          rolloverTimelinesRef.current.push({
            id: elem.id,
            timeline
          })
        }
      })
    }
  }, [rolloverImages])

  useEffect(() => {
    if (modalRef.current === null) return

    const timeline = gsap.timeline({
      paused: true,
      reversed: true
    })

    const mainNavItems = modalRef.current.querySelectorAll('.main-navigation ul li')

    timeline.set(modalRef.current, { zIndex: 45 })
    timeline.to(modalRef.current, { opacity: 1, duration: 0.3 })

    if (mainNavItems !== null) {
      mainNavItems.forEach((elem, index) => {
        timeline.fromTo(elem, { opacity: 0, y: '+=50' }, { opacity: 1, y: 0, duration: 0.3 }, index * 0.1)
      })
    }

    modalTimelineRef.current = timeline

    // CLEANUP
    // return () => {
    //   if (modalTimelineRef.current != null) {
    //     modalTimelineRef.current.revert()
    //     modalTimelineRef.current = null
    //   }
    // }
  }, [])

  return (
    <div ref={modalRef} className="fixed flex flex-col top-0 left-0 w-full h-full bg-primary overflow-y-auto pt-40 opacity-0 z-[-10]">
      <div className="relative flex flex-col grow lg:flex-row w-full h-full overflow-y-auto">
        <div className="relative flex flex-col w-full lg:w-auto">
          <div className="flex w-full md:grow">
            <div className="flex flex-col">
              {(mainNavigation != null) &&
                <nav className="main-navigation my-auto pb-6 lg:pt-0 lg:pb-0 ps-4 md:ps-8">
                  <ul>
                    {mainNavigation.menuItems?.nodes.filter((elem) => elem.path !== null).map((elem, index: number) => {
                      const last = !!((typeof itemsCount !== 'undefined' && index === itemsCount - 1))

                      return (
                        <li
                          key={`modalListMainItem${index}`}
                          onMouseEnter={() => {
                            const timelineToPlay = rolloverTimelinesRef.current.find((timeline) => timeline.id === elem.id)
                            if (timelineToPlay) {
                              timelineToPlay.timeline.play()
                            }
                          }}
                          onMouseLeave={() => {
                            const timelineToRevert = rolloverTimelinesRef.current.find((timeline) => timeline.id === elem.id)
                            if (timelineToRevert) {
                              timelineToRevert.timeline.seek(0).reversed(true)
                            }
                          }}
                          className={
                            classNames(
                              'relative',
                              'block',
                              'text-black',
                              'text-4xl',
                              'lg:text-6xl',
                              'xl:text-7-5xl',
                              'font-circular-book',
                              'hover:text-stroke-black',
                              'hover:text-transparent',
                              'hover:font-circular-book',
                              'whitespace-nowrap',
                              { 'mb-4': !last },
                              { 'md:mb-6': !last }
                            )}>
                          <span className="inline-block">
                            <Link
                              key={`modalListMainLink${index}`}
                              to={elem.path ?? '#'}
                              onClick={closeMenu}
                            >
                              {elem.label}
                            </Link>
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </nav>
              }
            </div>
          </div>
        </div>

        <div ref={rolloferWrapperRef} className="relative hidden md:flex flex-row md:flex-col w-full lg:w-auto lg:grow lg:h-full overflow-hidden">
          {rolloverImages && rolloverImages.map((elem, index) => {
            if (elem) {
              return (
                <div
                  key={`rolloverImage-${elem.id}`}
                  className="rollover-image absolute w-full top-1/2 right-0 transform -translate-y-1/2 opacity-0"
                  ref={(elem) => {
                    if (elem) {
                      rolloverImagesRef.current[index] = elem
                    }
                  }}
                  onMouseEnter={() => {
                    rolloverTimelinesRef.current.find((timeline) => timeline.id === elem.id)?.timeline.play()
                  }}
                  onMouseOut={() => {
                    rolloverTimelinesRef.current.find((timeline) => timeline.id === elem.id)?.timeline.reverse()
                  }}
                >
                  <GatsbyImage
                    image={elem.imageData}
                    alt=""
                  />
                </div>
              )
            }
            return null
          })}
        </div>
      </div>

      <div className="flex flex-row w-full ps-4 pe-4 md:ps-8 md:pe-8 pb-16">
        {(secondaryNavigation != null) &&
          <nav className="secondary-navigation">
            <ul>
              {secondaryNavigation.menuItems?.nodes.map((elem, index: number) => {
                return (
                  <li
                    key={`modalListMainItem${index}`}
                    className={
                      classNames(
                        'relative',
                        'block',
                        'font-circular-light',
                        'text-black',
                        'text-3xl',
                        'md:text-4xl',
                        'uppercase',
                        'font-bold'
                      )}
                    >
                    <Link
                      className="inline-block"
                      key={`modalListMainLink${index}`}
                      to={elem.path ?? '#'}
                      onClick={closeMenu}
                    >
                      {elem.label} <span className="icon-arrow-right"></span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        }
      </div>
    </div>
  )
}

export default MenuModal
