// NPM
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import parse from 'html-react-parser'

// CONTEXT
import { GlobalContext } from '../context/globalContext'

// TYPES
import type { GlobalContextProps } from '../types/custom-types'

interface PreHeaderProps {
  color: string | null | undefined
  text: string | null | undefined
  link: {
    url: string | null | undefined
    title: string | null | undefined
    target?: string | null | undefined
  } | null | undefined
}

const PreHeader: React.FC<PreHeaderProps> = ({ color, text, link }) => {
  const { isMenuOpen, isScrolled, isScrollingDown }: GlobalContextProps = useContext(GlobalContext)
  return (
    <>
      {link?.url && text &&
        <Link
          className={classNames(
            'fixed',
            'left-0',
            'z-60',
            'w-full',
            'px-3',
            'py-1',
            'transition',
            'transition-all',
            'duration-500',
            'ease-in-out',
            { [isScrolled && isScrollingDown && !isMenuOpen ? '-top-36' : 'top-0']: true }
          )}
          style={{
            backgroundColor: color ?? '#000'
          }}
          to={link.url}
          target={link.target ?? '_self'}
        >
          <div className="flex w-full">
            <div className="flex flex-wrap items-center mx-auto">
              <span className="inline-block text-white text-xs md:text-sm">
                {parse(text)}
                <span className="icon-arrow-right ms-2"></span>
              </span>
            </div>
          </div>
        </Link>
      }

      {!link?.url && text &&
        <div
          className={classNames(
            'fixed',
            'left-0',
            'z-60',
            'w-full',
            'px-3',
            'py-1',
            'transition',
            'transition-all',
            'duration-500',
            'ease-in-out',
            { [isScrolled && isScrollingDown && !isMenuOpen ? '-top-36' : 'top-0']: true }
          )}
          style={{
            backgroundColor: color ?? '#000'
          }}
        >
          <div className="flex w-full">
            <div className="flex flex-wrap items-center mx-auto">
              <span className="inline-block text-white text-xs md:text-sm">
                {parse(text)}
                <span className="icon-arrow-right ms-2"></span>
              </span>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default PreHeader
