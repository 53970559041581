// NPM
import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'

// CONTEXT
import { CookiesContext } from '../context/cookiesContext'

// HOOKS
import { useNavigationData } from '../hooks/navigationData'
import { useOptionsData } from '../hooks/optionsData'

// ASSETS
import FooterLogo from '../images/ilmagma_logo_white.inline.svg'

const Footer: React.FC = () => {
  const location = useLocation()
  const { openPreferences } = useContext(CookiesContext)
  const { socialsNavigation, privcyNavigation } = useNavigationData()
  const { siteOptions } = useOptionsData()
  const catalogUrl = siteOptions?.lavaSiteOptions?.siteOptionsDownloads?.catalog?.publicUrl
  const isHomePage = location.pathname === '/'

  return (
    <div className="flex flex-col justify-center w-full mx-auto bg-secondary text-sm text-tertiary">
      {isHomePage &&
        <>
          <div className="flex flex-col md:flex-row w-full lg:w-5/6 mx-auto pt-8 pb-8 md:pt-16 md:pb-16 ps-4 pe-4 md:pe-8 md:ps-8 lg:ps-0 lg:pe-0 pt-16">
            <div className="flex flex-col md:w-1/2 md:pe-24 mb-6 md:mb-0">
              <div className="my-auto text-base">
                <h3 className="text-5xl lg:text-8xl font-medium mb-6 font-circular-book">
                  About us
                </h3>
                <p className="mb-6">
                  Il Magma is a multi-disciplinary design studio founded in 2020 by Massimo Colonna and Chiara Ormelli.
                </p>
                <p className="mb-6">
                  We create digital and physical iconic experiences for brands. As a full-service studio, our work encompasses design, architecture, design, digital art, product design, advertising and communications, event production, video animation and sound design.
                </p>
                <a href="https://www.ilmagma.com" target="_blank" rel="noreferrer" className="text-sm inline-block uppercase rounded-full text-center border border-white pt-3 md:pt-4 pb-3 md:pb-4 pl-8 md:pl-12 pr-8 md:pr-12 transition transition-300 hover:bg-white hover:text-secondary">
                  Visit Our Website
                </a>
              </div>
            </div>

            <div className="flex flex-col md:w-1/2 md:pl-8">
              <div className="rounded-lg overflow-hidden">
                <StaticImage src="../images/about.jpg" alt="About" />
              </div>
            </div>
          </div>

          <hr className="flex w-full border-b border-white border-opacity-20" />
        </>
      }

      <div className="flex flex-col w-full lg:w-5/6 mx-auto pb-8 ps-4 pe-4 md:pe-8 md:ps-8 lg:ps-0 lg:pe-0 pt-8 pb-8 md:pt-16">
        {isHomePage &&
          <div className="flex flex-col md:flex-row w-full mb-6 lg:mb-48">
            <div className="flex w-3/4 mb-6 md:mb-0">
              <h3 className="text-3xl md:text-6xl font-circular-book my-auto leading-tight">
                Do you need more information about the products?
              </h3>
            </div>
            <div className="flex fex-col w-full md:w-1/4 md:justify-end">
              <div className="flex flex-col my-auto text-right">
                {catalogUrl &&
                  <a href={catalogUrl} target="_blank" rel="noreferrer" className="text-sm inline-block uppercase rounded-full text-center border border-white pt-3 md:pt-4 pb-3 md:pb-4 pl-8 md:pl-12 pr-8 md:pr-12 transition transition-300 hover:bg-white hover:text-secondary mb-4">
                    Download the catalog
                  </a>
                }

                {/* <a href="https://www.ilmagma.com" target="_blank" className="inline-block uppercase rounded-full text-center border border-white pt-4 pb-4 pl-12 pr-12 transition transition-300 hover:bg-white hover:text-secondary">
                  Visit Our Website
                </a> */}
              </div>
            </div>
          </div>
        }
        <div className="flex flex-col md:flex-row w-full md:justify-between">
          <div className="md:w-1/2 lg:w-1/4 mb-4 md:mb-0">
            <span className="font-circular-bold">Lava Srl</span>
            <br/>
            Piazza M. M. Boiardo, 10 - 42019
            <br/>
            Scandiano di Reggio Emilia (RE)
            <br/>
            VAT IT03068050354
            <br/>
            <br/>
            <a href="mailto:info@lava-project.com">
              info@lava-project.com
            </a>
          </div>

          <div className="md:w-1/2 lg:w-1/4 pb-4">
            {(socialsNavigation != null) &&
              <nav>
                <ul className="">
                  {socialsNavigation.menuItems?.nodes.filter((elem) => elem.path !== null).map((elem, index: number) => {
                    if (elem.url === null) return null
                    return (
                      <li key={`listSocialItem${index}`} className="uppercase">
                        <a key={`listSocialLink${index}`} href={elem.url} target="_blank" rel="noreferrer">
                          {elem.label}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            }
          </div>

          <div className="md:w-1/2 lg:w-1/4 pb-4 mb-6 md:mb-0">
            {(privcyNavigation != null) &&
              <nav>
                <ul>
                  {privcyNavigation.menuItems?.nodes.filter((elem) => elem.path !== null).map((elem, index: number) => {
                    if (elem.url === null || elem.path === null) return null
                    return (
                      <li key={`listPrivacyItem${index}`}>
                        {elem.cssClasses?.includes('iubenda-cs-preferences-link') && openPreferences !== undefined
                          ? <a
                              key={`listPrivacyLink${index}`}
                              href={elem.url}
                              className={elem.cssClasses?.join(' ')}
                              onClick={openPreferences}
                            >
                              {elem.label}
                            </a>
                          : <Link
                            key={`listPrivacyLink${index}`}
                            to={elem.path}
                          >
                            {elem.label}
                          </Link>
                        }
                      </li>
                    )
                  })}
                </ul>
                © {new Date().getFullYear()} Il Magma.
              </nav>
            }
          </div>

          <div className="flex flex-col md:w-1/2 lg:w-1/4 pb-4">
            <a href="https://www.ilmagma.com">
              <FooterLogo className="w-full h-auto max-w-220 my-auto md:ml-auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
