// NPM
import { graphql, useStaticQuery } from 'gatsby'

export const useOptionsData = (): Queries.optionsDataQuery => {
  const data = useStaticQuery(graphql`
    query optionsData {
      siteOptions: wpPage(id: {eq: "cG9zdDoy"}) {
        lavaSiteOptions {
          siteOptionsPreHeader {
            active
            color
            text
            link {
              url
              title
              target
            }
          }
          siteOptionsDownloads {
            catalog {
              publicUrl
            }
          }
          siteOptionsGif {
            gif {
              publicUrl
            }
          }
        }
      }
    }
  `)
  return data
}
